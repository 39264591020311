<template>
  <div v-padding:top="46">
    <van-nav-bar title="应急预案" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          name="name"
          v-model="formData.name"
          label="应急预案名称"
          placeholder="请填写应急预案名称"
          :rules="[{ required: true, message: '请填写应急预案名称' }]"
        />
        <van-field
          name="code"
          v-model="formData.code"
          label="应急预案编号"
          placeholder="请填写应急预案编号"
          :rules="[{ required: true, message: '请填写应急预案编号' }]"
        />
        <van-field
          readonly
          clickable
          name="approvalTime"
          v-model="formData.approvalTime"
          label="审批时间"
          placeholder="请选择审批时间"
          @click="calendarShow = true"
          :rules="[{ required: true, message: '请选择审批时间' }]"
        />
        <van-field name="uploader" label="附件">
          <template #input>
            <van-uploader
              v-model="file"
              :max-count="1"
              :max-size="10240000"
              :after-read="afterRead"
              :before-read="beforeRead"
              @click-preview="clickPrev"
              @delete="deleteFile"
            />
          </template>
        </van-field>
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-calendar
      v-model="calendarShow"
      type="single"
      :default-date="defaultDate"
      @confirm="onConfirm"
      :allow-same-day="true"
      :style="{ height: '62%' }"
      color="#378aed"
    />
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        name: "",
        code: "",
        approvalTime: "",
      },
      file: [],
      uploadedFile: [],
      fileType: [],
      submitLoading: false,
      calendarShow: false,
      defaultDate: this.$moment().toDate(),
      calendarType: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.operate == "edit") {
      this.getEmergencyInfo();
    }
  },
  methods: {
    beforeRead(file) {
      const flag = new RegExp(
        "[`~!@#$^&*=|{}':;',\\[\\]<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？%]"
      );
      if (flag.test(file.name)) {
        Toast.fail({
          message: "文件名不能包含特殊字符",
        });
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.fileType = this.fileType.concat(file.file.name);
      file.status = "uploading";
      const data = new FormData();
      data.append("file", file.file);
      this.$api.setting.uploadFile(data).then((res) => {
        this.uploadedFile = this.uploadedFile.concat(res.data.data[0].id);
        file.status = "done";
      });
    },
    clickPrev(file) {
      const { name } = "name" in file ? file : file.file;
      const suffixIndex = name.lastIndexOf(".");
      const suffix = name.substring(suffixIndex + 1).toUpperCase();
      if (
        suffix != "BMP" &&
        suffix != "JPG" &&
        suffix != "JPEG" &&
        suffix != "PNG" &&
        suffix != "GIF"
      ) {
        Toast.fail("不支持在线查看，请到PC端下载");
      }
    },
    deleteFile(file, detail) {
      file.status = "uploading";
      const { index } = detail;
      const fileId = this.uploadedFile[index];
      const typeId = this.fileType[index];
      const data = {
        id: fileId,
      };
      this.$api.common.getDeleteFile(data).then((res) => {
        if (res.data.state == 0) {
          this.uploadedFile = this.uploadedFile.filter((item) => {
            return item != fileId;
          });
          this.fileType = this.fileType.filter((item) => {
            return item != typeId;
          });
          file.status = "done";
        }
      });
    },
    // 新增排污许可证
    addEmergency() {
      this.submitLoading = true;
      const data = {
        enterpriseId: this.enterpriseId,
        name: this.formData.name,
        code: this.formData.code,
        approvalTime: this.$moment(this.formData.approvalTime).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .postEmergencyAddList(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 修改排污许可证
    editEmergency() {
      this.submitLoading = true;
      const data = {
        id: this.formData.id,
        enterpriseId: this.enterpriseId,
        name: this.formData.name,
        code: this.formData.code,
        approvalTime: this.$moment(this.formData.approvalTime).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .postEmergencyEditList(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    onSubmit() {
      if (this.$route.query.operate == "add") {
        this.addEmergency();
      } else if (this.$route.query.operate == "edit") {
        this.editEmergency();
      }
    },
    // 日历确定
    onConfirm(date) {
      this.formData.approvalTime = this.$moment(date).format("YYYY-MM-DD");
      this.calendarShow = false;
    },
    // 获取排污许可证详情
    getEmergencyInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting.getArcMeetMeasureById(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = {
            id: res.data.data.id,
            name: res.data.data.name,
            code: res.data.data.code,
            approvalTime: res.data.data.approvalTime
              ? this.$moment(res.data.data.approvalTime).format("YYYY-MM-DD")
              : "",
          };
          if (res.data.data.minioFiles.length > 0) {
            const tempArr = [];
            const tempFileIds = [];
            const fileArr = res.data.data.minioFiles || [];
            fileArr.forEach((element) => {
              tempArr.push({
                name: element.fileName,
                url: element.url,
                id: element.id,
              });
              tempFileIds.push(element.id);
            });
            this.file = tempArr;
            this.uploadedFile = tempFileIds;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
